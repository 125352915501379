import { SET_VIDEO, SET_PLAY_LIST, SET_CURRENT_VLIST, SET_TRACK, CLEAR_MEDIA, SET_VIDEO_CATEGORY,SET_MEDIA_SETTING } from "."

export const setVideo = (data) => {
    return {
        type: SET_VIDEO,
        payload: data
    }
}
export const setPlayList = (data) => {
    return {
        type: SET_PLAY_LIST,
        payload: data
    }
}
export const setVideoCategory = (data) => {
    return {
        type: SET_VIDEO_CATEGORY,
        payload: data
    }
}
export const setCurrentVList = (data) => {
    return {
        type: SET_CURRENT_VLIST,
        payload: data
    }
}
export const setTrack = (data) => {
    return {
        type: SET_TRACK,
        payload: data
    }
}
export const setMediaSetting = (data) => {
    return {
        type: SET_MEDIA_SETTING,
        payload: data
    }
}
export function initMedia(data, dispatch) {
    let datas = JSON.parse(JSON.stringify(data));
    if (data && data.length > 0) {
        dispatch(setVideo(datas));
        
        let list = {};
        data.forEach((item, i) => {
            let { category, tags: TG } = item;
            if (list[category]) {
                item.tags.forEach((item, i) => {
                    if (!list[category]['items'].includes(item)) {
                       list[category]['items'].push(item)
                    }
                })

            } else {
                list[category] = {
                    name: category,
                    items: TG
                }
            }
        })
        let categories = Object.values(list);
        let CAT_NUM = categories.length;
        if (CAT_NUM > 0) {
            dispatch(setVideoCategory(categories));
            let { name, items } = categories[0];
            //set play list 
            let plist = data.filter(item => item.category === name);
            if(plist.length>0){
                dispatch(setPlayList(plist));
                //Set cuurent vlist 
                dispatch(setCurrentVList(plist[0]));
                //set track
                dispatch(setTrack({total: plist.length, current: 0}));
            }
        }

    }
}
export function updateMedia(plist, dispatch) {
    if (plist && plist.length > 0) {
        //set play list 
        dispatch(setPlayList(plist));
        //Set cuurent vlist 
        dispatch(setCurrentVList(plist[0]));
        //set track
        dispatch(setTrack({total: plist.length, current:0}));
    }
}
export function nextMedia(data,index,total, dispatch) {console.log(data)
     //Set cuurent vlist 
     dispatch(setCurrentVList(data));
     //set track
     dispatch(setTrack({total: total, current: index}));
}
export function mediaSetting(data,dispatch) {         
     dispatch(setMediaSetting(data));
}
