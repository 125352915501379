import React, { useState } from 'react'
import { useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import Tables from './Tables';

function TablePagination(props) {
    const { items, itemsPerPage, correntItems, colums } = props;
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);

    // Simulate fetching items from another resources.
    // (This could be items from props; or items loaded in a local state
    // from an API endpoint with useEffect and useState)
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    const currentItems = items.slice(itemOffset, endOffset);

    const pageCount = Math.ceil(items.length / itemsPerPage);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % items.length;
        setItemOffset(newOffset);
    };
    useEffect(() => {
        //correntItems(currentItems);
    }, [])
    useEffect(() => {
        //correntItems(currentItems);
    }, [itemOffset, items])
    return (
        <>
            <Tables data={currentItems} colums={colums} actions={props?.actions}/>
            {
                pageCount > 1 && (
                    <nav aria-label="Page navigation example" className='Page navigation mt-2'>
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel="next >"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={5}
                            pageCount={pageCount}
                            previousLabel="< previous"
                            renderOnZeroPageCount={null}
                            containerClassName="Page navigation"
                            className="pagination justify-content-center"
                            pageClassName="page-item"
                            activeClassName="page-item active"
                            pageLinkClassName="page-link"
                            activeLinkClassName="page-link active"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            disabledClassName="page-item disabled"
                            disabledLinkClassName="page-link disabled"
                        />
                    </nav>
                )
            }

        </>
    )
}

export default TablePagination