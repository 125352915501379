import * as md from './media.action'
export const MediaAction = md;
//AUTH
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

//MESSAGE
export const SET_MESSAGE = "SET_MESSAGE";
export const SET_ERROR = "SET_ERROR";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

//Form
export const ADD_ACTION = "ADD_ACTION";
export const EDIT_ACTION = "EDIT_ACTION";
export const DELETE_ACTION = "DELETE_ACTION";
export const FETCH_ACTION = "FETCH_ACTION";
export const CLEAR_ACTION = "CLEAR_ACTION";
//DATA
export const CLEAR_DATA = "CLEAR_DATA";
export const SET_CATEGORY = "SET_CATEGORY";
export const SET_BRAND = "SET_BRAND";
export const SET_PRODUCT = "SET_PRODUCT";
export const SET_PROFILE = "SET_PROFILE";
export const GET_PROFILE = "GET_PROFILE";
//MEDIA
export const SET_VIDEO = "SET_VIDEO";
export const GET_VIDEO = "GET_VIDEO";
export const SET_VIDEO_CATEGORY = "SET_VIDEO_CATEGORY";
export const GET_VIDEO_CATEGORY = "GET_VIDEO_CATEGORY";
export const SET_PLAY_LIST = "SET_PLAY_LIST";
export const GET_PLAY_LIST = "GET_PLAY_LIST";
export const SET_CURRENT_VLIST = "SET_CURRENT_VLIST";
export const GET_CURRENT_VLIST = "GET_CURRENT_VLIST";
export const SET_TRACK = "SET_TRACK";
export const GET_TRACK = "GET_TRACK";
export const SET_MEDIA_SETTING = "SET_MEDIA_SETTING";
export const GET_MEDIA_SETTING = "GET_MEDIA_SETTING";
export const CLEAR_MEDIA = "CLEAR_MEDIA";

export const logout = () => {
    return {
        type:LOGOUT
    }
}
export const setMediaAction = (data) => {
    return {
        type:SET_VIDEO,
        payload:data
    }
}
export const setPlayListAction = (data) => {
    return {
        type:SET_VIDEO,
        payload:data
    }
}
