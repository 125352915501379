import Form from 'react-bootstrap/Form';

function SelectInput(props) {
    let {data,selected,handler,name} = props
  return (
    <Form.Select aria-label="Default select example" onChange={handler} value={selected} name={name}>
      <option>Select</option>
      {
        data && data.length>0 && data.map((item,i) => (
            <option key={i} value={item.id} selected={selected?'selected':''}>{item.name}</option>
        ))
      }
    </Form.Select>
  );
}

export default SelectInput;