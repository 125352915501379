import React from 'react'
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from "react-router-dom";
import { useAuth, AuthProvider } from "../hooks/useAuth";
function PrivateRoutes() {
    const { isLoggedIn,user:USER } = useSelector(state => state.auth);
    /* const { user } = useAuth();
    console.log(user); */
    let auth = { token: isLoggedIn }
    return (
        auth.token ? <AuthProvider userData={USER}><main><Outlet /></main></AuthProvider> : <Navigate to="/" />
    )
}

export default PrivateRoutes