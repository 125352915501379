import { EDIT_ACTION,CLEAR_ACTION, ADD_ACTION, DELETE_ACTION } from "../actions";

const initialState = {
  module:null,
  actionType:null,
  id:null,
  last:Date.now()
};

export default function (state = initialState, action) {
  const { type, payload,status=null } = action;

  switch (type) {
    case ADD_ACTION:
      return { ...state,message: payload,last:Date.now() };

    case EDIT_ACTION:
      return { ...state,id: payload,last:Date.now() };

    case DELETE_ACTION:
        return { ...state,id: payload,last:Date.now() };

    case CLEAR_ACTION:
      return initialState;

    default:
      return state;
  }
}
