import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider, Container, Row, Col, Button, ListGroup, Nav } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import VideoPlayer from './components/VideoPlayer';
import TopMenubar from './components/layout/TopMenubar';
import RouterMain from './routes';

function App() {
  
  return (
    <ThemeProvider
      breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
      minBreakpoint="xxs"
      prefixes={{ btn: 'my-btn' }}>
      <TopMenubar />
      <Container fluid>
        <RouterMain/>
      </Container>
      <ToastContainer />
    </ThemeProvider>
  );
}

export default App;
