import React from 'react'
import DropdownTreeSelect from 'react-dropdown-tree-select'
import 'react-dropdown-tree-select/dist/styles.css'

function CheckboxDropdownTree(props) {
    const data = props.data || {
        label: 'search me',
        value: 'searchme',
        children: [
            {
                label: 'search me too',
                value: 'searchmetoo',
                children: [
                    {
                        label: 'No one can get me',
                        value: 'anonymous',
                    },
                ],
            },
        ],
    }

    const onChange = (currentNode, selectedNodes) => {
        //console.log('onChange::', currentNode, selectedNodes)
        props.handler({...props.value,['parent_id']:currentNode.value})
    }
    const onAction = (node, action) => {
        //console.log('onAction::', action, node)
    }
    const onNodeToggle = currentNode => {
        //console.log('onNodeToggle::', currentNode)
    }
    return (
        <div className='w-100'>
            <DropdownTreeSelect 
            data={data} 
            onChange={onChange} 
            onAction={onAction} 
            onNodeToggle={onNodeToggle}
            className='w-100'
            />
        </div>
    )
}

export default CheckboxDropdownTree
