import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import TablePagination from '../../components/TablePagination';
import Table from 'react-bootstrap/Table';
import { BrandService } from '../../services';
import Buttons from '../../components/Buttons';
import { PAGES } from '../../utils';

function BrandList() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const loadData = async () => {
    setLoading(true);
    try {
      let response = await BrandService.All();
      //response = await response.json();
      console.log(response)
      setData(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }
  useEffect(() => {
    loadData();
  }, [])
  const colums = [
    { label: '#ID', key: 'id' },
    { label: 'Name', key: 'name' },
    { label: 'Description', key: 'description' },
  ]
  const actions = [
    {title:'Edit',url:'brand',type:'u'},
    {title:'View',url:'brand',type:'r'},
    {title:'Delete',url:'brand',type:'d'},
  ]
  return (
    <div className='container-fluid'>
      <div className='row bg-light d-flex align-items-center mt-3'>
        <div className='col'>
          <h3 className='text-info'>Brands</h3>
        </div>
        <div className='col'>
          <Buttons title="Add New" type="link" url={PAGES.BRAND_ADD} />
        </div>
      </div>
      <TablePagination
        items={data}
        itemsPerPage={10}
        colums={colums} 
        actions={actions}
        />
    </div>
  )
}

export default BrandList
