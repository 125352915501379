import { combineReducers } from 'redux';

import auth from './auth';
import message from './message';
import form from './form';
import data from './data';
import media from './media';

const rootReducer = combineReducers({
    auth, message, form, data, media
});

export default rootReducer;