import React from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { errorHandler, Services } from '../../services';
const Login = (props) => (
    <div>
        <Formik
            initialValues={{ email: '', password: '' }}
            validate={values => {
                const errors = {};
                if (!values.email) {
                    errors.email = 'Required';
                } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                    errors.email = 'Invalid email address';
                }
                if (!values.password) {
                    errors.password = 'Required';
                }
                return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
                try {
                    let res = await Services.Auth.login(values);
                    const {success,message,data} = res.data;
                    if(success){                        
                        toast.success(message);
                        localStorage.setItem("user",JSON.stringify(data));
                        props.open(false);
                        window.location.reload();
                    }
                } catch (error) {
                    let msg = errorHandler(error);
                    toast.error(msg);
                }                
                setTimeout(() => {              
                    setSubmitting(false);                    
                }, 400);
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
            }) => (
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control type="email"
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            placeholder="Enter email" />
                        <Form.Text className="text-danger">
                            {errors.email && touched.email && errors.email}
                        </Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password"
                            name="password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                            placeholder="Password" />                        
                        <Form.Text className="text-danger">
                        {errors.password && touched.password && errors.password}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" label="Remember me" />
                    </Form.Group>
                    <Button variant="primary" type="submit" className='btn btn-primary' disabled={isSubmitting}>
                        Submit
                    </Button>
                </Form>
            )}
        </Formik>
    </div>
);

export default Login;