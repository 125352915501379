import React, { useEffect } from 'react';
import { Form, Button, Card } from 'react-bootstrap';
import { useNavigate, useParams } from "react-router-dom";
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { ProductService, BrandService,CategoryService } from '../../services';
import { useState } from 'react';
import Buttons from '../../components/Buttons';
import SelectInput from '../../components/SelectInput';
import ProductStepForm from './ProductStepForm';

const ProductForm = (props) => {
    let navigate = useNavigate();
    let { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [brands, setBrand] = useState([]);
    const [category, setCategory] = useState([]);
    const [data, setData] = useState({ 
        name: '', 
        price:'',
        quantity:0,
        publish:false,
        category_id:'',
        brand_id:'',
        description: ''
     });
    const loadData = async () => {
        setLoading(true);
        try {
            let response = await ProductService.View({ id });
            setData(response.data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }
    const loadBrand = async () => {
        setLoading(true);
        try {
            let response = await BrandService.All();
            setBrand(response.data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }
    const loadCategory = async () => {
        setLoading(true);
        try {
            let response = await CategoryService.All();
            setCategory(response.data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }
    useEffect(() => {
        loadData();
        loadBrand();
        loadCategory();
    }, [])
    const onChange = e => {
        let {name,value} = e.target;
        setData({...data,[name]:value})
        console.log(name,value,data)
    }

    return (
        <div className='form-container container'>
            <div className='row w-100'>
                <div className='col'>
                <ProductStepForm/>
                </div>
            </div>
            <Formik className="d-none"
                enableReinitialize={true}
                initialValues={data}
                validate={values => {
                    let errors = {}
                    if (parseInt(id)) {
                        values = data
                    }
                    if (!values.name) {
                        errors.name = 'Required';
                    }
                    return errors;
                }}
                onSubmit={async (values, { setSubmitting }) => {
                    try {
                        let response = null;
                        if (parseInt(id)) {
                            response = await ProductService.Update({ id, param: values });
                        } else {
                            response = await ProductService.Create({ param: values });
                        }

                        const { success, message, data } = response;
                        if (success) {
                            toast.success(message);
                            return navigate("/products");
                        }
                    } catch (error) {
                        if (!error.success && error.data) {
                            Object.entries(error.data).forEach(([k, v]) => {
                                toast.error(v[0]);
                            })
                        }
                    }
                    setTimeout(() => {
                        setSubmitting(false);
                    }, 400);
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                }) => (
                    <Card style={{ width: '56rem',display:'none' }}>
                        <Card.Header>Product Form</Card.Header>
                        <Card.Body>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Brand</Form.Label>
                                    <SelectInput
                                    name="brand_id"
                                    data={brands}
                                    selected={values.brand_id || data.brand_id}
                                    handler={onChange}
                                    />                                    
                                    <Form.Text className="text-danger">
                                        {errors.brand_id && touched.brand_id && errors.brand_id}
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Category</Form.Label>
                                    <SelectInput
                                    name="category_id"
                                    data={category}
                                    selected={data.category_id}
                                    handler={onChange}
                                    />
                                    <Form.Text className="text-danger">
                                        {errors.category_id && touched.category_id && errors.category_id}
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control type="text"
                                        name="name"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.name || data.name}
                                        placeholder="Enter name" />
                                    <Form.Text className="text-danger">
                                        {errors.name && touched.name && errors.name}
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Quantity</Form.Label>
                                    <Form.Control type="text"
                                        name="quantity"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.quantity || data.quantity}
                                        placeholder="Enter quantity" />
                                    <Form.Text className="text-danger">
                                        {errors.quantity && touched.quantity && errors.quantity}
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Price</Form.Label>
                                    <Form.Control type="text"
                                        name="price"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.price || data.price}
                                        placeholder="Enter price" />
                                    <Form.Text className="text-danger">
                                        {errors.price && touched.price && errors.price}
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Check 
                                        type="switch"
                                        id="custom-switch"
                                        label="publish"
                                        name="publish"
                                        onChange={e => setData({...data,[e.target.name]:e.target.checked})}
                                        onBlur={handleBlur}
                                        value={values.publish || data.publish}
                                        checked={values.publish || data.publish}
                                    />
                                    
                                    <Form.Text className="text-danger">
                                        {errors.publish && touched.publish && errors.publish}
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control as="textarea" rows={3}
                                        name="description"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.description || data.description}
                                        placeholder="Enter description" />
                                    <Form.Text className="text-danger">
                                        {errors.description && touched.description && errors.description}
                                    </Form.Text>
                                </Form.Group>
                                <Button variant="primary" type="submit" className='btn btn-primary' disabled={isSubmitting}>
                                    Submit
                                </Button>{' '}
                                <Buttons title="Cancle" type="link" url="/brands" />
                            </Form>
                        </Card.Body>
                    </Card>
                )}
            </Formik>
        </div>
    )
}

export default ProductForm;