import React, { useEffect, useState, useRef, Suspense } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import TablePagination from '../../components/TablePagination';
import Buttons from '../../components/Buttons';
import { PAGES } from '../../utils';
import { CategoryService } from '../../services';

function CategoryList() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [currentItems, setCurrentItems] = useState([]);
  const loadData = async () => {
    setLoading(true);
    try {
      let response = await CategoryService.All();
      //response = await response.json();
      console.log(response)
      setData(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }
  useEffect(() => {
    loadData();
  }, [])
  const colums = [
    {label:'#ID',key:'id'},
    {label:'Name',key:'name'},
    {label:'Description',key:'description'},
  ]
  const actions = [
    {title:'Edit',url:'category',type:'u'},
    {title:'View',url:'category',type:'r'},
    {title:'Delete',url:'category',type:'d'},
  ]
  return (
    <div className='container-fluid'>
      <div className='row bg-light d-flex align-items-center mt-3'>
        <div className='col'>
          <h3 className='text-info'>Category</h3>
        </div>
        <div className='col'>
          <Buttons title="Add New" type="link" url={PAGES.CATEGORY_ADD} />
        </div>
      </div>      
      <TablePagination 
      items={data}
      itemsPerPage={10}
      colums={colums} 
      actions={actions} />
    </div>
  )
}

export default CategoryList
