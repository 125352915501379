import React, { useState } from 'react'
import { Form, Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Login from '../../screens/login';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, Link } from "react-router-dom";
import Register from '../../screens/register';
import { logout } from '../../redux/actions';
import { PAGES } from '../../utils';
function TopMenubar() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isLoggedIn, user, token } = useSelector(state => state.auth);
    const [showLogin, setShowLogin] = useState(false);
    const [showRegister, setShowRegister] = useState(false);
    const logoutHandler = (e) => {
        e.preventDefault();
        toast.success('You are successfully logged out!');
        dispatch(logout());
        localStorage.clear("user");
        navigate("/dashboard");
    }
    return (
        <>
            <div>
                <div className="superNav border-bottom py-2 bg-light">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 centerOnMobile">
                                <select className="me-3 border-0 bg-light">
                                    <option value="en-us">EN-US</option>
                                </select>
                                <span className="d-none d-lg-inline-block d-md-inline-block d-sm-inline-block d-xs-none me-3"><strong>info@somedomain.com</strong></span>
                                <span className="me-3"><i className="fa-solid fa-phone me-1 text-warning" /> <strong>1100-123-1234</strong></span>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 d-none d-lg-block d-md-block-d-sm-block d-xs-none text-end">
                                <span className="me-3"><i className="fa-solid fa-truck text-muted me-1" /><a className="text-muted" href="#">Shipping</a></span>
                                <span className="me-3"><i className="fa-solid fa-file  text-muted me-2" /><a className="text-muted" href="#">Policy</a></span>
                            </div>
                        </div>
                    </div>
                </div>
                <nav className="navbar navbar-expand-lg bg-white sticky-top navbar-light p-3 shadow-sm">
                    <div className="container">
                        <Link className="navbar-brand" to="/"><i className="fa-solid fa-shop me-2" /> <strong>Media</strong></Link>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon" />
                        </button>

                        <div className=" collapse navbar-collapse" id="navbarNavDropdown">
                            <ul className="navbar-nav ms-auto ">
                                <li className="nav-item">
                                    <a className="nav-link mx-2 text-uppercase active" aria-current="page" href="#">Offers</a>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link mx-2 text-uppercase" to={PAGES.SHOP}>Shopping</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link mx-2 text-uppercase" to={PAGES.SERVICES}>Services</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link mx-2 text-uppercase" to={PAGES.ABOUT}>About</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link mx-2 text-uppercase" to={PAGES.CONTACT}>Contact</Link>
                                </li>
                                {
                                    isLoggedIn && (
                                        <li className="nav-item dropdown">
                                            <a className="nav-link dropdown-toggle nav-link mx-2 text-uppercase" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                CPanel
                                            </a>
                                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                <li><Link className="dropdown-item nav-link mx-2 text-uppercase" to={PAGES.BRAND}>Brand</Link></li>
                                                <li><Link className="dropdown-item nav-link mx-2 text-uppercase" to={PAGES.CATEGORY}>Category</Link></li>
                                                <li><Link className="dropdown-item nav-link mx-2 text-uppercase" to={PAGES.PRODUCT}>Product</Link></li>
                                                <li><hr className="dropdown-divider" /></li>
                                                <li><Link className="dropdown-item nav-link mx-2 text-uppercase" to={PAGES.PRODUCT}>Album</Link></li>
                                            </ul>
                                        </li>
                                    )
                                }
                            </ul>
                            <ul className="navbar-nav ms-auto">
                                {/* <li className="nav-item">
                                    <a className="nav-link mx-2 text-uppercase" href="#"><i className="fa-solid fa-cart-shopping me-1" /> Cart</a>
                                </li> */}
                                <li className="nav-item">
                                    {isLoggedIn ? (
                                        <div className='d-flex justify-content-between'>
                                            <Link className="nav-link mx-2 text-uppercase" to="/profile"><i className="fa-solid fa-circle-user me-1" /> {user?.name}</Link>
                                            <a className="nav-link mx-2 text-uppercase" href="#" onClick={e => logoutHandler(e)}><i className="fa-solid fa-sign-out me-1 text-danger" /></a>
                                        </div>
                                    ) : (
                                        <div className='d-flex justify-content-between'>
                                            <a className="nav-link mx-2 text-uppercase" href="#" onClick={() => setShowRegister(!showRegister)}><i className="fa-solid fa-circle-user me-1" /> Register</a>
                                            <a className="nav-link mx-2 text-uppercase" href="#" onClick={() => setShowLogin(!showLogin)}><i className="fa-solid fa-circle-user me-1" /> Login</a>
                                        </div>
                                    )}
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>

                {/* LOGIN MODULE */}
                <Modal show={showLogin} onHide={() => setShowLogin(!showLogin)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Login</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Login open={setShowLogin} />
                    </Modal.Body>
                    {/* <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowLogin(!showLogin)}>
                            Close
                        </Button>
                        <Button variant="primary">
                            Save Changes
                        </Button>
                    </Modal.Footer> */}
                </Modal>
                {/* REGISTER MODULE */}
                <Modal show={showRegister} onHide={() => setShowRegister(!showRegister)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Register</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Register open={setShowRegister} />
                    </Modal.Body>
                    {/* <Modal.Footer>
                        <Button variant="secondary" onClick={() => showRegister(!showRegister)}>
                            Close
                        </Button>
                        <Button variant="primary">
                            Save Changes
                        </Button>
                    </Modal.Footer> */}
                </Modal>
            </div>
        </>
    )
}

export default TopMenubar;
