import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';
import { BsCheckCircle,BsDashCircle } from "react-icons/bs";
import TableAction from './TableAction';

function Tables({ colums, data,actions }) {
  return (
    <div>
      <Table responsive striped>
        <thead>
          <tr>
            {
              colums && colums.length > 0 && colums.map((col, i) => <th key={i}>{col.label}</th>)
            }
            <th></th>
          </tr>
        </thead>
        <tbody>
          {
            data && data.length > 0 && data.map((item, i) => (
              <tr key={i}>
                {
                  colums && colums.length > 0 && colums.map((col, i) => (
                    col.type === Boolean?(
                      <td key={i}>{item[col.key]? <BsCheckCircle/>:<BsDashCircle/>}</td>
                    ):(
                      <td key={i}>{item[col.key]}</td>
                    )
                  ))
                }
                <td><TableAction actions={actions} id={item.id}/></td>
              </tr>
            ))
          }
        </tbody>
      </Table>
    </div>
  )
}

export default Tables;
