import { SET_CATEGORY,SET_BRAND,SET_PRODUCT,CLEAR_DATA,SET_PLAY_LIST } from "../actions";

const initialState = {
  categories:null,
  barnds:null,
  products:null,
  play_lists:null,
  last:Date.now()
};

export default function (state = initialState, action) {
  const { type, payload,status=null } = action;

  switch (type) {
    case SET_CATEGORY:
      return { ...state,categories: payload,last:Date.now() };    
    case SET_BRAND:
      return { ...state,barnds: payload,last:Date.now() };    
    case SET_PRODUCT:
      return { ...state,products: payload,last:Date.now() };    
    case SET_PLAY_LIST:
      return { ...state,play_lists: payload,last:Date.now() };    

    case CLEAR_DATA:
      return initialState;

    default:
      return state;
  }
}
