import { SET_MESSAGE, CLEAR_MESSAGE, SET_ERROR } from "../actions";

const initialState = {
  message:null,
  error:null,
  status:null,
  last:Date.now()
};

export default function (state = initialState, action) {
  const { type, payload,status=null } = action;

  switch (type) {
    case SET_MESSAGE:
      return { ...state,message: payload,last:Date.now() };

    case SET_ERROR:
      return { ...state,error: payload,last:Date.now() };

    case CLEAR_MESSAGE:
      return initialState;

    default:
      return state;
  }
}
