import { ListGroup } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import VideoPlayer from './../../components/VideoPlayer';
import { MediaAction } from '../../redux/actions';

function PlayList() {
  const dispatch = useDispatch();
  const { videos, play_lists, vlist_categories, current_vlist, track } = useSelector(state => state.media)
  const [select, setSelect] = useState({ category: null, title: null });
  const [loading, setLoading] = useState(false);

  const loadData = async () => {
    setLoading(true);
    try {
      let response = await fetch("https://ibdbgs.com/ut.song.php");
      response = await response.json();
      let { youtube } = response;
      MediaAction.initMedia(youtube, dispatch)
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    loadData();
  }, [])

  useEffect(() => {
    if (vlist_categories && vlist_categories.length > 0) {
      setSelect({ ...select, category: vlist_categories[0]['name'] || null, title: current_vlist?.title || null });
    }
  }, [vlist_categories])

  const clickHandler = (data, i) => {
    let Num = play_lists.length;
    let currentIndex = i;
    MediaAction.nextMedia(data, currentIndex, Num, dispatch);
    setSelect({ ...select, title: data.title });
  }
  const selectHandler = (category = null, title = null) => {
    if (category && title) {
      setSelect({ ...select, category, title });
    } else if (category && !title) {
      setSelect({ ...select, category });
    } else if (!category && title) {
      setSelect({ ...select, title });
    }

  }
  const filterBy = (type, fidler) => {
    let datas = JSON.parse(JSON.stringify(videos));
    let items;
    if (type === 'cat') {
      items = datas.filter(item => item.category === fidler);
    } else if (type === 'item') {
      items = datas.filter(item => item.tags.includes(fidler));
    }
    MediaAction.updateMedia(items, dispatch);
    setSelect({ ...select, category: fidler, title: items[0]['title'] });
    //console.log('FILTER',type,fidler,items)
  }
  const gridClass = "col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xll-9"
  return (
    <div className='container-fluid'>
      <div className="jumbotron row mt-5">
        <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xll-9 mb-9">
          <div className="card">
            <div className="card-header text-center">
              <h3 className="card-title">{select?.title || 'NA'}</h3>
            </div>
            <div className="card-body v-container" id="video-container">
              {
                current_vlist && Object.keys(current_vlist).length > 0 && (
                  <VideoPlayer selector={selectHandler} />
                )
              }
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xll-3 mb-3">
          <div className="card">
            <div className="card-header text-center">
              <h3 className="card-title">{select?.category || 'NA'}</h3>
            </div>
            <div className="card-body v-container right-nav">
              <ListGroup>
                {play_lists.length > 0 && play_lists.map((item, i) => (
                  <ListGroup.Item key={i} onClick={() => clickHandler(item, i)}
                    className={`pointer ${track?.current === i ? 'active' : ''}`}
                  >
                    {i + 1}.{item.title}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xs-12">
        <h2>Our next?</h2>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <hr />
        </div>

      </div>

      {
        vlist_categories.length > 0 && vlist_categories.map((item, i) => (
          <div className="mt-2 card" key={i}>
            <div className='card-header bg-light'>
              <h3 className='text-info pointer' onClick={() => filterBy('cat', item.name)}>{item.name}</h3>
            </div>
            <div className='row g-4 py-5  card-body' key={i}>
              {
                item.items.length > 0 && item.items.map((item, i) => (
                  <div className=" col-sm-12 col-md-3" key={i}>
                    <h5 className="card-title pointer" onClick={() => filterBy('item', item)}>{item}</h5>
                  </div>
                ))
              }
            </div>
          </div>
        ))
      }
      <div className="footer">
        <div className="row">
          <div className="text-center" style={{ marginBottom: 10 }}>
            <a href="#" rel="nofollow">Client Login</a>
          </div>
          <div className="copyright text-center">
          Power by IBDBGS and this is only for Entertainment and study.
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlayList;
