import axios from 'axios';
import { SET_ERROR, SET_MESSAGE } from '../redux/actions';
import { API } from "../utils";
import * as Auth from './auth.service'
import { httpHandler } from './httpHandler';
import * as Category from './category.services';
import * as Brand from './brand.service';
import * as Product from './product.service';

export const AuthService = Auth;
export const CategoryService = Category;
export const BrandService = Brand;
export const ProductService = Product;

export const errorHandler = (error) => {
    const message =
        (error.response &&
            error.response.data &&
            error.response.data.message || 
            error.response.data.error.message) ||
        error.message ||
        error.toString();
    return message;
}

export const apiHandler = async (param, dispatch,store) => {
    
    //const ACCESS_KEY = await getToken();
    var ACCESS_KEY = store?.token||'TYIUTYHJVHFHGV';
    //Check token
    
    if (!ACCESS_KEY) {
        dispatch && dispatch({ type: SET_MESSAGE, payload: 'Access key expire or invalid' })
        return;
    };

    if (!param.URL) {
        dispatch && dispatch({ type: SET_MESSAGE, payload: 'API URL missing' })
        return;
    };
    return await httpHandler(param,ACCESS_KEY);
}

export const getToken = (user) => {    
    return user?.token;
}

export const cloneService = async (url,id) => {
    let obj={param:{id},token:null}
    let Path = `${API.BASE_URL}/${url}/clone`
    return await httpHandler({URL:Path,METHOD:'POST',PARAM:obj.param},obj.token);
}

export const Services = {
    Auth,Category,httpHandler,getToken,apiHandler,errorHandler
}
