import axios from "axios";
import { apiHandler, errorHandler } from "./";
import { SET_ERROR, SET_PROFILE } from '../redux/actions';
import { httpHandler } from "./httpHandler";
import { API } from "../utils"

export const register = async (data) => {
    return await axios.post(API.RESISTER, data);
}

export const login = async ({ email, password }) => {
    return await axios.post(API.LOGIN, { email, password });
}

export const user = async (dispatch) => {
    try {
        const result = await apiHandler({
            URL: API.PROFILE
        }, dispatch);
        //console.log(result);        
        if (!result) return result;
        const { success, data } = result.data;
        if (success) {
            dispatch({ type: SET_PROFILE, payload: data });
        }
    } catch (err) {
        console.log(err);
        dispatch({ type: SET_ERROR, payload: errorHandler(err) })
    }
}