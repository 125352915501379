import { API } from "../utils";
import { httpHandler } from "./httpHandler";

export const All = async (obj={}) => {
    return await httpHandler({URL:API.PRODUCT,METHOD:'GET',PARAM:obj.param},obj.token);
}
export const Create = async (obj={}) => {
    return await httpHandler({URL:API.PRODUCT,METHOD:'POST',PARAM:obj.param},obj.token);
}

export const Update = async (obj={}) => {
    let Path = API.PRODUCT+"/"+obj.id;
    return await httpHandler({URL:Path,METHOD:'PUT',PARAM:obj.param},obj.token);
}

export const View = async (obj={}) => {
    let Path = API.PRODUCT+"/"+obj.id;
    return await httpHandler({URL:Path,METHOD:'GET',PARAM:obj.param},obj.token);
}