var myHeaders = new Headers();
myHeaders.append("Accept", "application/json");
myHeaders.append("Content-Type", "application/json");

export function httpHandler(param, token = null) {
  const { METHOD = 'POST', URL, PARAM = {} } = param;
  let t = getToken();
  if(t){
    token = t;
  }
  if (token) {
    delete myHeaders["Authorization"];
    myHeaders.append("Authorization", `Bearer ${token}`);
  }
  var raw = JSON.stringify(PARAM);

  var requestOptions = {
    method: METHOD,
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };
  if(METHOD === 'GET' || METHOD === 'DELETE'){
    delete requestOptions['body'];
  }
  //console.log('API RQ -> ',requestOptions);
  return new Promise((resolve, reject) => {
    fetch(URL, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log('endPoint => ', result);
        if (result.success) {
          return resolve(result);
        } else {
          return reject(result);
        }
      })
      .catch(error => {
        console.log('error', error);
        return reject(error);
      });
  })

}

const getToken = () => {
  let user = JSON.parse(localStorage.getItem("user"));
  return user?user.token:null;
}