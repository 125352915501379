import { SET_VIDEO, SET_PLAY_LIST, SET_CURRENT_VLIST, SET_TRACK, CLEAR_MEDIA, SET_VIDEO_CATEGORY,SET_MEDIA_SETTING } from "../actions";

const initialState = {
    videos: [],
    vlist_categories:[],
    play_lists: [],
    current_vlist:{},
    settings:{auto_play:false,auto_nex:false},
    track:{total:0,current:0},
    last: Date.now()
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case SET_VIDEO:
            return { ...state, videos: payload };
        case SET_VIDEO_CATEGORY:
            return { ...state, vlist_categories: payload };
        case SET_PLAY_LIST:
            return { ...state, play_lists: payload };
        case SET_CURRENT_VLIST:
            return { ...state, current_vlist: payload };
        case SET_TRACK:
            return { ...state, track: payload };
        case SET_MEDIA_SETTING:
            return { ...state, settings: payload };
        case CLEAR_MEDIA:
            return initialState;
        default:
            return state;
    }
}
