import React, { useEffect, useState, useRef, Suspense } from 'react'
import ReactPlayer from 'react-player'
import Form from 'react-bootstrap/Form';
import { useSelector, useDispatch } from 'react-redux';
import { MediaAction } from '../redux/actions';
import Spinner from './Spinner';

function VideoPlayer(props) {
    const dispatch = useDispatch();
    let { play_lists, current_vlist, track, settings } = useSelector(state => state.media);
    let { selector } = props;
    const [video_url, setVideoUrl] = useState(null);
    useEffect(() => {
        if (Object.values(current_vlist).length > 0) {
            let url = current_vlist.url.split("&")[0];
            setVideoUrl(url)
        }
    }, [current_vlist])

    const nextHandler = () => {
        console.log('ENDED', track);
        let currID = track.current + 1;
        let nextID = currID;
        if (track.total > currID) {
            let data = play_lists[nextID];
            MediaAction.nextMedia(data, nextID, play_lists.length, dispatch);
            selector(null, data['title']);
        } else {
            let data = play_lists[0];
            MediaAction.nextMedia(data, 0, play_lists.length, dispatch);
            selector(null, data['title']);
        }
    }
    const errorHandler = () => {
        nextHandler()
    }
    return (
        <>
            <Suspense fallback={<Spinner />}>
                <div className='player-wrapper'>
                    {
                        video_url && (
                            <ReactPlayer
                                className='react-player mb-2'
                                width='100%'
                                height='100%'
                                controls={true}
                                playing={settings?.auto_play}
                                url={video_url}
                                onEnded={nextHandler}
                                onError={() => errorHandler()}
                            />
                        )
                    }
                </div>
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xll-9">
                        <div className='d-flex content-justify-between'>
                            <Switch title="Auto Play" attr="auto_play" />
                            <Switch title="Auto Next" attr="auto_next" />
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xll-3 d-flex flex-row-reverse">
                        <div className='text-right'>{track.current}/{track.total}</div>
                    </div>
                </div>

            </Suspense>
        </>
    )
}

export default VideoPlayer;

function Switch({ title, attr }) {
    const dispatch = useDispatch();
    let { settings } = useSelector(state => state.media);
    const [switchAction, setSwitchAction] = useState(settings?.attr || false);
    const onChange = e => {
        setSwitchAction(!e.target.checked)
        if (attr === 'auto_play') {
            MediaAction.mediaSetting({ auto_play: switchAction }, dispatch);
        } else {
            MediaAction.mediaSetting({ auto_next: switchAction }, dispatch);
        }

    }

    return (
        <Form className='me-2'>
            <Form.Check
                type="switch"
                id="custom-switch"                
                label={title || "Auto"}
                value={settings?.attr}
                onChange={onChange}
                checked={settings?.attr}
            />
        </Form>
    );
}