import React from 'react'
import { Link } from 'react-router-dom';
import { BsPencilSquare, BsDashCircle, BsFolder2Open } from "react-icons/bs";
import { toast } from 'react-toastify';
import { cloneService } from '../services';

function TableAction(props) {
  console.log('TableAction', props)
  let { actions, id} = props
  const cloneHandler = async (url,id) => {
    try {
      let response = await cloneService(url,id);
      console.log(response)
  } catch (error) {
      console.log(error);
  }
  }
  const manageIcon = item => {
    let icon = '';
    switch (item.type) {
      case 'u':
        icon = <BsPencilSquare />;
        break;
      case 'r':
        icon = <BsFolder2Open />;
        break;
      case 'd':
        icon = <BsDashCircle />;
        break;
      default:
        //icon = item.title;
        break;
    }
    return (
      <>
        {icon} {item.title}
      </>
    )
  }
  if (!actions) {
    return (<></>)
  }
  return (
    <div className="dropdown" style={{ zIndex: '999' }}>
      <i style={{ fontSize: '16px', cursor: 'pointer' }} className="fa dropdown-toggle-" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">&#xf142;</i>
      <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
        {
          actions && actions.length > 0 && actions.map((item, i) => (
            <li>
              {
                item.type === 'clone' ? (
                  <Link className="dropdown-item" to="#" onClick={() => cloneHandler(item.url,id)}>
                    {manageIcon(item)}
                  </Link>
                ) : (
                  <Link className="dropdown-item" to={`/${item.url}/${id}`}>
                    {manageIcon(item)}
                  </Link>
                )
              }
            </li>
          ))
        }
      </ul>
    </div>

  );
}

export default TableAction
