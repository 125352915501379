const BASE_API_URL = process.env.REACT_APP_BASE_API_URL || 'https://ibdbgs.com/saguniya/api';
export const API = {
    BASE_URL:`${BASE_API_URL}`,
    LOGIN:`${BASE_API_URL}/login`,
    RESISTER:`${BASE_API_URL}/register`,
    PROFILE:`${BASE_API_URL}/user`,
    POST:`${BASE_API_URL}/posts`,
    CATEGORY:`${BASE_API_URL}/categories`,
    BRAND:`${BASE_API_URL}/brands`,
    PRODUCT:`${BASE_API_URL}/products`,
}

export const PAGES = {
    HOME:'/',
    DASHBOARD:'/dashboard',
    ABOUT:'/about',
    CONTACT:'/contact',
    LOGIN:`/login`,
    RESISTER:`/register`,
    PROFILE:'/profile',
    CHANGE_PASSWORD:'/change-password',
    FORGET_PASSWORD:'/forget-password',
    POST:'/posts',
    BRAND:'/brands',
    BRAND_ADD:'/brand/add',
    BRAND_UPDATE:'/brand/:id',
    CATEGORY:'/categories',
    CATEGORY_ADD:'/category/add',
    CATEGORY_UPDATE:'/category/:id',
    PRODUCT_ADD:'/product/add',
    PRODUCT_UPDATE:'/product/:id',
    PRODUCT:'/products',
    SHOP:'/shop',
    SERVICES:'/services',
}