import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Home from '../screens/pages/Home';
import About from '../screens/pages/About';
import Contact from '../screens/pages/Contact';
import { PAGES } from '../utils';
import { ProtectedRoute } from './ProtectedRoute';
import PrivateRoutes from './PrivateRoutes';
import BrandList from '../screens/brands';
import CategoryList from '../screens/category';
import ProductList from '../screens/products';
import BrandForm from '../screens/brands/BrandForm';
import CategoryForm from '../screens/category/CategoryForm';
import ProductForm from '../screens/products/ProductForm';

function RouterMain() {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path={PAGES.ABOUT} element={<About />} />
            <Route path={PAGES.CONTACT} element={<Contact />} />
            <Route path={PAGES.SHOP} element={<Contact />} />
            <Route element={<PrivateRoutes/>}>
                <Route path={PAGES.PROFILE} element={<ProfilePage />} />
                <Route path={PAGES.DASHBOARD} element={<Dashboard />} />
                <Route path={PAGES.CATEGORY} element={<CategoryList />} />
                <Route path={PAGES.CATEGORY_UPDATE} element={<CategoryForm />} />
                <Route path={PAGES.CATEGORY_ADD} element={<CategoryForm />} />     
                <Route path={PAGES.BRAND} element={<BrandList />} />
                <Route path={PAGES.BRAND_UPDATE} element={<BrandForm />} />
                <Route path={PAGES.BRAND_CU} element={<BrandForm />} />                
                <Route path={PAGES.PRODUCT} element={<ProductList />} />
                <Route path={PAGES.PRODUCT_ADD} element={<ProductForm />} />
                <Route path={PAGES.PRODUCT_UPDATE} element={<ProductForm />} />
            </Route>
            <Route path="*" element={<PageNotFound />} />
        </Routes>
    )
}

export default RouterMain;

function ProfilePage() {
    return (<h3>Profile</h3>)
}
function Dashboard() {
    return (<h3>Dashboard</h3>)
}
function CommingSoon() {
    return (<h3>Coming Soon</h3>)
}
function PageNotFound() {
    return (<h3>404</h3>)
}