import React from 'react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom';

function Buttons(props) {
    const {
        title,type='btn',url=null
    } = props;
  return (
    <div className='d-flex justify-content-end my-2'>
        {
            type==='btn'?(<Button>{title}</Button>):(<Link to={url} className="btn btn-primary">{title}</Link>)
        }     
      
    </div>
  )
}

export default Buttons
