import React from 'react'
import PlayList from '../media'

function Home() {
  return (
    <div>
      <PlayList/>
    </div>
  )
}

export default Home
