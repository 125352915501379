import React, { useEffect, useState} from 'react'
import TablePagination from '../../components/TablePagination';
import { ProductService } from '../../services';
import Buttons from '../../components/Buttons';
import { PAGES } from '../../utils';
function ProductList() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const loadData = async () => {
      setLoading(true);
      try {
        let response = await ProductService.All();
        console.log(response)
        let products = response.data.map(item => {
            return {...item,brand_name:item.brand.name,category_name:item.category.name}
        })
        setData(products);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
    useEffect(() => {
      loadData();
    }, [])
    const colums = [
      {label:'#ID',key:'id',type:Number},
      {label:'Name',key:'name',type:String},
      {label:'Price',key:'price',type:Number},      
      {label:'Quantity',key:'quantity',type:Number},      
      {label:'Brand',key:'brand_name',type:String},
      {label:'Category',key:'category_name',type:String},
      {label:'Publish',key:'publish',type:Boolean},
    ]
    const actions = [
      {title:'Edit',url:'product',type:'u'},
      {title:'View',url:'product',type:'r'},
      {title:'Clone',url:'products',type:'clone'},
      {title:'Delete',url:'product',type:'d'},
    ]
    return (
      <div className='container-fluid'>
        <div className='row bg-light d-flex align-items-center mt-3'>
        <div className='col'>
          <h3 className='text-info'>Product</h3>
        </div>
        <div className='col'>
          <Buttons title="Add New" type="link" url={PAGES.PRODUCT_ADD} />
        </div>
      </div>      
        <TablePagination 
        items={data} 
        itemsPerPage={10} 
        colums={colums} actions={actions}/>
      </div>
    )
}

export default ProductList
