import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
} from "../actions";

//import { storeData,getData } from "../../utils/inddex";

const StorageKey = "user";
var user = localStorage.getItem(StorageKey) || null;
if(user){
  user = JSON.parse(user);
}
load();
async function load(){
  /* if(!user){
    await storeData(StorageKey,"")
  } else {
    await getData(StorageKey);
  } */
}

const initialState = user
  ? { 
    isLoggedIn: true, user,token:null,type:'user'
   }
  : { isLoggedIn: false, user: null };

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case REGISTER_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        isLoggedIn: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload.name,token:payload.token
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    case LOGOUT:
      return { ...state,isLoggedIn: false, user: null,token:null };
    default:
      return state;
  }
}

