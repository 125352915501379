import { API } from "../utils";
import { httpHandler } from "./httpHandler";

export const All = async (obj={}) => {
    return await httpHandler({URL:API.CATEGORY,METHOD:'GET',PARAM:obj.param},obj.token);
}
export const Create = async (obj={}) => {
    return await httpHandler({URL:API.CATEGORY,METHOD:'POST',PARAM:obj.param},obj.token);
}

export const Update = async (obj={}) => {
    let Path = API.CATEGORY+"/"+obj.id;
    return await httpHandler({URL:Path,METHOD:'PUT',PARAM:obj.param},obj.token);
}
export const View = async (obj={}) => {
    let Path = API.CATEGORY+"/"+obj.id;
    return await httpHandler({URL:Path,METHOD:'GET',PARAM:obj.param},obj.token);
}
export const Tree = async (obj={}) => {
    let Path = API.CATEGORY+"/tree";
    return await httpHandler({URL:Path,METHOD:'GET',PARAM:obj.param},obj.token);
}
/* export const Delete = async (id) => {
    let Path = API.CATEGORY+"/"+id;
    return await httpHandler({URL:Path,METHOD:'DELETE',PARAM:obj.param},obj.token);
} */