import React from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { errorHandler, Services } from '../../services';
const Register = () => (
    <div>
        <Formik
            initialValues={{ name:'',email: '',phone:'', password: '',c_password:'' }}
            validate={values => {
                const errors = {};
                /* if (!values.first_name) {
                    errors.first_name = 'First name is required';
                }
                if (!values.last_lame) {
                    errors.last_lame = 'Last name is required';
                } */
                if (!values.name) {
                    errors.name = 'Name is required';
                }
                if (!values.email) {
                    errors.email = 'Required';
                } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                    errors.email = 'Invalid email address';
                }
                if (!values.password) {
                    errors.password = 'Required';
                }
                if (!values.c_password) {
                    errors.c_password = 'Required';
                }
                if (values.password !== values.c_password) {
                    errors.c_password = 'Miss match';
                }
                return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
                try {
                    let res = await Services.Auth.register(values); console.log(res);
                    const { success, message, data } = res.data;
                    if (success) {
                        toast.success(message);
                        localStorage.setItem("user", JSON.stringify(data));
                    }
                } catch (error) {
                    let msg = errorHandler(error);
                    toast.error(msg);
                }

                setTimeout(() => {
                    setSubmitting(false);
                }, 400);
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
            }) => (
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="Name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" 
                        name="name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                        placeholder="Enter Name" />
                        <Form.Text className="text-danger">
                            {errors.name && touched.name && errors.name}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control type="email"
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            placeholder="Enter email" />
                        <Form.Text className="text-danger">
                            {errors.email && touched.email && errors.email}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="Phone Number">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control type="number" 
                        name="phone"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.phone}
                        placeholder="Enter Phone" />
                        <Form.Text className="text-danger">
                            {errors.phone && touched.phone && errors.phone}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password"
                            name="password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                            placeholder="Password" />                        
                        <Form.Text className="text-danger">
                        {errors.password && touched.password && errors.password}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control type="password"
                            name="c_password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.c_password}
                            placeholder="Confirm Password" />                        
                        <Form.Text className="text-danger">
                        {errors.c_password && touched.c_password && errors.c_password}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" label="Check me out" />
                    </Form.Group>
                    <Button variant="primary" type="submit" className='btn btn-primary' disabled={isSubmitting}>
                        Submit
                    </Button>
                </Form>
            )}
        </Formik>
    </div>
);

export default Register;