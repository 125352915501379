import React,{useEffect} from 'react';
import { Form, Button, Card } from 'react-bootstrap';
import { useNavigate,useParams } from "react-router-dom";
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { BrandService, errorHandler } from '../../services';
import { useState } from 'react';
import Buttons from '../../components/Buttons';

const BrandForm = (props) => {
    let navigate = useNavigate();
    let { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({ name: '', description: '' });
  const loadData = async () => {
    setLoading(true);
    try {
      let response = await BrandService.View({id});
      setData(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }
  useEffect(() => {
    loadData();
  }, [])
    return (
        <div className='form-container'>
        <Formik
            enableReinitialize={true}
            initialValues={data}
            validate={values => { 
                let errors = {}   
                if(parseInt(id)){
                    values = data
                }            
                if (!values.name) {
                    errors.name = 'Required';
                }
                return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
                try {
                    let response = null;
                    if(parseInt(id)){ 
                        response = await BrandService.Update({id,param:values});
                    } else {
                        response = await BrandService.Create({param:values});
                    }
                    
                    const { success, message, data } = response;
                    if (success) {
                        toast.success(message);
                        return navigate("/brands");
                    }
                } catch (error) { 
                    if(!error.success && error.data){
                        Object.entries(error.data).forEach(([k,v]) => { 
                            toast.error(v[0]);
                        })
                    }
                }
                setTimeout(() => {
                    setSubmitting(false);
                }, 400);
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
            }) => (
                <Card style={{width:'36rem'}}>
                    <Card.Header>Brand Form</Card.Header>
                    <Card.Body>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text"
                                    name="name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.name||data.name}
                                    placeholder="Enter name" />
                                <Form.Text className="text-danger">
                                    {errors.name && touched.name && errors.name}
                                </Form.Text>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Description</Form.Label>
                                <Form.Control as="textarea" rows={3} 
                                    name="description"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.description||data.description}
                                    placeholder="Enter description"
                                    />
                                <Form.Text className="text-danger">
                                    {errors.description && touched.description && errors.description}
                                </Form.Text>
                            </Form.Group>
                            <Button variant="primary" type="submit" className='btn btn-primary' disabled={isSubmitting}>
                                Submit
                            </Button>{' '}
                           
                            <Buttons title="Cancle" type="link" url="/brands"/>
                        </Form>
                    </Card.Body>
                </Card>
            )}
        </Formik>
    </div>
    )
}

export default BrandForm;